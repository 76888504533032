<!-- Mto Mensajes Generales -->

<template>
  <div class="msgGenM" v-if="loaded">
    <dualTemplate
      :dualTipo="sync? '':'modal'"
      :modal="modal"
      :storeName="storeRaiz"
       persistent>

        <template v-slot:controles="{}">

          <div>

            <!-- Cabecera -->
            <div class="cabecera">
              <baseHeader
                :cfg="$cfe.headers.mto"
                :titulo='$store.state[storeName].titulo'
                @onEvent="eventHeader">
              </baseHeader>
            </div>


            <v-sheet :elevation="4">
              <div class="contenedor">

                <!-- Botones Mto -->
                <baseBtraMto class="conflex"
                  :perm="permMto"
                  :modulo="btMtoCfg"
                  :estado="estado"
                  @onEvent="execAccion">
                </baseBtraMto>

                <div style="padding-top:10px"></div>

                <!-- CTDS -->
                <div class="cab">CTD</div>
                <v-sheet v-bind="$cfg.styles.marco">
                  <v-textarea
                    style="width:620px"
                    v-bind="$textarea"
                    v-model="ct.msg_general1[2]"
                    :label="ct.msg_general1[1]"
                    rows="5"
                    no-resize
                    :disabled="noEdit">
                  </v-textarea>
                </v-sheet>

                <!-- APDS -->
                <div class="cab">APDS</div>
                <v-sheet v-bind="$cfg.styles.marco">
                  <v-textarea
                    style="width:620px"
                    v-bind="$textarea"
                    v-model="ct.msg_general2[2]"
                    :label="ct.msg_general2[1]"
                    rows="5"
                    no-resize
                    :disabled="noEdit">
                  </v-textarea>
                </v-sheet>

                <!-- CONSULTAS -->
                <div class="cab">CONSULTAS</div>
                <v-sheet v-bind="$cfg.styles.marco">
                  <v-textarea
                    style="width:620px"
                    v-bind="$textarea"
                    v-model="ct.msg_general3[2]"
                    :label="ct.msg_general3[1]"
                    rows="5"
                    no-resize
                    :disabled="noEdit">
                  </v-textarea>
                </v-sheet>
              </div>
            </v-sheet>
          </div>
        </template>
    </dualTemplate>
  </div>
</template>



<script>

  import { mixM } from "@/mixins/mixM.js";
  import baseHeader from "@/base/baseHeader";
  import baseBtraMto from "@/base/baseBtraMto";
  import dualTemplate from "@/components/dualTemplate";

  export default {
    mixins: [mixM],
    components: { baseHeader, baseBtraMto, dualTemplate },
    props: { },

    data() {
      return {
        stIni: {
          api: "msgGenM",
          name:"msgGenM",
          titulo:"·Mensajes Generales",
          recordAccess:"local",
          mView:'',
          pView:[]
        },

      };
    },


    methods: {
      iniDataParticular() {
        console.log("DEV " + this.stIni.api + " ********************** (iniDataParticular)");

        this.btnSet(this.btMtoCfg, 1, { view:true, disabled:true });
        this.btnSet(this.btMtoCfg, 3, { view:true, disabled:true });
      },

    },


    computed: {
      ID() {
        return 1;
      }
    }
  };
</script>
